import { all, fork } from "redux-saga/effects";
import AuthSaga from "./AuthSaga";
import GlobalSaga from "./GlobalSaga";
import AnnouncementSaga from "./AnnouncementSaga";
import TaskSaga from "./TaskSaga";
import QuestSaga from "./QuestSaga";
import CollectionSaga from "./CollectionSaga";
import PrizePoolSaga from "./PrizePoolSaga";
import VotesSaga from "./VotesSaga";
import FamilySaga from "./FamilySaga";
import UserSaga from "./UserSaga";
import BoostSaga from "./BoostSaga";
import GameWalletsSaga from "./GameWalletsSaga";
import ReportsSaga from "./ReportsSaga";
export function* rootSaga() {
  yield all([fork(AuthSaga)]);
  yield all([fork(GlobalSaga)]);
  yield all([fork(AnnouncementSaga)]);
  yield all([fork(TaskSaga)]);
  yield all([fork(QuestSaga)]);
  yield all([fork(CollectionSaga)]);
  yield all([fork(PrizePoolSaga)]);
  yield all([fork(VotesSaga)]);
  yield all([fork(BoostSaga)]);
  yield all([fork(FamilySaga)]);
  yield all([fork(UserSaga)]);
  yield all([fork(GameWalletsSaga)]);
  yield all([fork(ReportsSaga)]);
}
