import { put, takeLatest } from "redux-saga/effects";
import { types } from "../action_types";
import { GET, POST } from "../../Utils/httpServices";
import CustomToster from "../../SharedComponents/CustomToaster";
const token = window.sessionStorage.getItem("token");

function* GetAllUserDataSaga(action) {
  const { payload } = action;
  const { limit, page, searchText } = payload;
  const actualSearchText = searchText || "";
  const actualPage = page || "";
  const actualLimit = limit || "";

  try {
    const userAllData_response = yield GET(
      `/admin/user/allUsers?page=${actualPage}&limit=${actualLimit}&name=${actualSearchText}`,
      // `/admin/user/allUsersForPDF?page=${actualPage}&limit=${actualLimit}&name=${actualSearchText}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    let responsedata = { ...userAllData_response?.data };

    if (userAllData_response?.status === 200) {
      yield put({
        type: types.GetAllUserDataRequestSuccess,
        payload: { responsedata, actualSearchText },
      });
    } else {
      yield put({ type: types.GetAllUserDataRequestFailure });
    }
  } catch (e) {
    CustomToster({
      type: "error",
      message: e?.response?.data?.result?.details?.MESSAGE || e?.message,
    });

    yield put({ type: types.GetAllUserDataRequestFailure });
  }
}

function* GetUserDetailSaga(userId) {
  try {
    const userData_response = yield GET(
      `/admin/user/profile/${userId?.payload}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const userInventoryResponse = yield GET(
      `/admin/inventory/user/${userId?.payload}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    let responsedata = { ...userData_response?.data };

    if (userData_response?.status === 200) {
      yield put({
        type: types.GetUserDetailRequestSuccess,
        payload: responsedata,
      });
      yield put({
        type: types.GetUserInventory,
        payload: userInventoryResponse?.data.result,
      });
    } else {
      yield put({ type: types.GetUserDetailRequestFailure });
    }
  } catch (e) {
    CustomToster({
      type: "error",
      message: e?.response?.data?.result?.details?.MESSAGE || e?.message,
    });

    yield put({ type: types.GetUserDetailRequestFailure });
  }
}

function* UpdateUserStatus({ payload }) {
  const { userId, isBlock, currentPage } = payload;

  try {
    const userStatusResponse = yield POST(
      `admin/user/${isBlock ? "block" : "unblock"}/${userId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (userStatusResponse?.status === 200) {
      yield put({
        type: types.GetAllUserData,
        payload: currentPage,
      });
      CustomToster({
        type: "success",
        message: `User ${isBlock ? "blocked" : "unblocked"} successfully`,
      });
    }
  } catch (e) {
    CustomToster({
      type: "error",
      message: e?.response?.data?.result?.details?.MESSAGE || e?.message,
    });
  }
}

function* DeleteUser({ payload }) {
  const { userId, currentPage } = payload;

  try {
    const userStatusResponse = yield POST(`admin/user/delete/${userId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (userStatusResponse?.status === 200) {
      yield put({
        type: types.GetAllUserData,
        payload: currentPage,
      });
      CustomToster({
        type: "success",
        message: `User deleted successfully`,
      });
    }
  } catch (e) {
    CustomToster({
      type: "error",
      message: e?.response?.data?.result?.details?.MESSAGE || e?.message,
    });
  }
}

function* RestoreUser({ payload }) {
  const { userId, currentPage } = payload;

  try {
    const userStatusResponse = yield POST(`admin/user/restore/${userId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (userStatusResponse?.status === 200) {
      yield put({
        type: types.GetAllUserData,
        payload: currentPage,
      });
      CustomToster({
        type: "success",
        message: `User restored successfully`,
      });
    }
  } catch (e) {
    CustomToster({
      type: "error",
      message: e?.response?.data?.result?.details?.MESSAGE || e?.message,
    });
  }
}

export default function* UserSaga() {
  yield takeLatest(types.GetAllUserData, GetAllUserDataSaga);
  yield takeLatest(types.GetUserDetail, GetUserDetailSaga);
  yield takeLatest(types.UpdateUserStatus, UpdateUserStatus);
  yield takeLatest(types.DeleteUser, DeleteUser);
  yield takeLatest(types.RestoreUser, RestoreUser);
}
