import { Modal, Pagination } from "antd";
import CustomCard from "../../../../SharedComponents/Cards";
import { useState } from "react";
import UserDetail from "./UserDetail";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { types } from "../../../../store/action_types";
import SearchBar from "../../../../SharedComponents/SearchBar";
import { Row, Col, Select, Button, Spin } from "antd";
import { BASEURL } from "../../../../Constants";
import { message } from "antd";
import moment from "moment";

const { Option } = Select;
const token = window.sessionStorage.getItem("token");

const UserCardData = () => {
  const dispatch = useDispatch();
  const { allUserData, userDetailData } = useSelector((state) => ({
    allUserData: state.UserReducer?.allUserData,
    userDetailData: state.UserReducer?.UserDetailData,
    userDetailDataLoading: state.UserReducer?.userDetailDataLoading,
  }));

  const { searchTextUsers } = useSelector((state) => ({
    searchTextUsers: state.QuestReducer.searchText,
  }));

  const [currentPage, setCurrentPage] = useState(1);
  const [userDetailModal, setUserDetailModal] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [pageLimit, setPageLimit] = useState(10);
  const [restoreUserModal, setRestoreUserModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleCloseUserDetail = () => {
    setUserDetailModal(false);
  };

  const showUserClicked = (item) => {
    console.log("item clicked mee", item);
    if (item.isDeleted) {
      setSelectedUser(item._id);
      setRestoreUserModal(true);
    } else {
      dispatch({
        type: types.GetUserDetail,
        payload: item?._id,
      });
      setUserDetailModal(true);
    }
  };

  useEffect(() => {
    dispatch({
      type: types.GetAllUserData,
      payload: {
        page: 1,
        limit: pageLimit,
      },
    });
  }, []);

  useEffect(() => {}, [allUserData]);

  const pageChanged = (page) => {
    setCurrentPage(page);
    dispatch({
      type: types.GetAllUserData,
      payload: {
        page,
        limit: pageLimit,
        searchText: searchText ? searchText : searchTextUsers,
      },
    });
  };

  const handleBlockUser = (isBlock, userId) => {
    dispatch({
      type: types.UpdateUserStatus,
      payload: {
        isBlock,
        userId,
        currentPage,
      },
    });
  };

  const handleDeleteUser = (userId) => {
    dispatch({
      type: types.DeleteUser,
      payload: {
        userId,
        currentPage,
      },
    });
  };

  const handleRestoreUser = (userId) => {
    dispatch({
      type: types.RestoreUser,
      payload: {
        userId,
        currentPage,
      },
    });
  };

  const handleSearch = (searchText) => {
    setSearchText(searchText);
    dispatch({
      type: types.GetAllUserData,
      payload: {
        searchText: searchText,
        page: currentPage,
        limit: pageLimit,
      },
    });
  };

  const handlePerPageChange = (value) => {
    setPageLimit(value);
    dispatch({
      type: types.GetAllUserData,
      payload: {
        page: 1,
        searchText: searchText ? searchText : searchTextUsers,
        limit: value,
      },
    });
  };

  const handleConfirmRestore = () => {
    dispatch({
      type: types.RestoreUser,
      payload: {
        userId: selectedUser,
        currentPage,
      },
    });
    setRestoreUserModal(false);
  };

  const downloadUsers = () => {
    if (currentPage <= 0) {
      message.error("Invalid page number");
      return;
    }

    const dataToDownload = allUserData?.result?.docs || [];

    if (dataToDownload.length === 0) {
      message.info("No data available to download");
      return;
    }

    const formattedData = dataToDownload.map((item) => ({
      user: item?.nickName ? item?.nickName : "N/A",
      createdAt: item?.createdAt ? item?.createdAt : "N/A",
      "Wallet Address": item?.walletAddress ? item?.walletAddress : "N/A",
      country: item?.countryName ? item?.countryName : "N/A",
      "Country Code": item.countryCode ? item?.countryCode : "N/A",
      city: item?.city ? item?.city : "N/A",
      level: item?.level ? item?.level : "N/A",
      userXP: item?.userXp?.totalXps ? item?.userXp?.totalXps : "N/A",
      monthlyXps: item?.userXp?.monthlyXps ? item?.userXp?.monthlyXps : "N/A",
      weeklyXps: item?.userXp?.weeklyXps ? item?.userXp?.weeklyXps : "N/A",
      todayXps: item?.userXp?.todayXps ? item?.userXp?.todayXps : "N/A",
      email: item?.email ? item?.email : "N/A",
      id: item?.walletAddress ? item?.walletAddress : "N/A",
      currentLocationCountryName: item?.currentLocation?.countryName
        ? item?.currentLocation?.countryName
        : "N/A",
      currentLocationCountryCode: item?.currentLocation?.countryCode
        ? item?.currentLocation?.countryCode
        : "N/A",
      currentLocationCity: item?.currentLocation?.city
        ? item?.currentLocation?.city
        : "N/A",
      currentLocationLatitude: item?.currentLocation?.latitude
        ? item?.currentLocation?.latitude
        : "N/A",
      currentLocationLongitude: item?.currentLocation?.longitude
        ? item?.currentLocation?.longitude
        : "N/A",
      id: item?.deviceId ? item?.deviceId : "N/A",
      deviceId: item?.deviceId ? item?.deviceId : "N/A",
      imageUrl: item?.imageUrl ? item?.imageUrl : "N/A",
      isBlocked: item?.isBlocked ? item?.isBlocked : "N/A",
      isBlockedByAdmin: item?.isBlockedByAdmin ? item?.isBlockedByAdmin : "N/A",
      isDeleted: item?.isDeleted ? item?.isDeleted : "N/A",
      phoneCountryCode: item?.phone?.phoneCountryCode
        ? item?.phone?.phoneCountryCode
        : "N/A",
      phoneCode: item?.phone?.phoneCode ? item?.phone?.phoneCode : "N/A",
      phoneNumber: item?.phone?.phoneNumber ? item?.phone?.phoneNumber : "N/A",
      fullPhoneNumber: item?.phone?.fullPhoneNumber
        ? item?.phone?.fullPhoneNumber
        : "N/A",
      role: item?.role ? item?.role : "N/A",
      socialMediaLinksFaceBook: item?.socialMediaLinks?.faceBook
        ? item?.socialMediaLinks?.faceBook
        : "N/A",
      socialMediaLinksTwitter: item?.socialMediaLinks?.twitter
        ? item?.socialMediaLinks?.twitter
        : "N/A",
      userCompletedTasks: item?.userCompletedTasks
        ? item?.userCompletedTasks
        : "N/A",
      userTotalNfts: item?.userTotalNfts ? item?.userTotalNfts : "N/A",
    }));

    const csvContent = [
      Object.keys(formattedData[0]).join(","),
      ...formattedData.map((row) => Object.values(row).join(",")),
    ].join("\n");

    const blob = new Blob([csvContent], { type: "text/csv" });

    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = "Users.csv";
    link.click();
  };

  const downloadAllUsers = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${BASEURL}admin/user/allUsersForPDF`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error("Failed to fetch user data");
      }

      const data = await response.json();
      const dataToDownload = data?.result || [];
      const formattedData = dataToDownload.map((item) => ({
        user: item?.nickName ? item?.nickName : "N/A",
        createdAt: item?.createdAt ? item?.createdAt : "N/A",
        "Wallet Address": item?.walletAddress ? item?.walletAddress : "N/A",
        country: item?.countryName ? item?.countryName : "N/A",
        "Country Code": item.countryCode ? item?.countryCode : "N/A",
        city: item?.city ? item?.city : "N/A",
        level: item?.level ? item?.level : "N/A",
        userXP: item?.userXp?.totalXps ? item?.userXp?.totalXps : "N/A",
        monthlyXps: item?.userXp?.monthlyXps ? item?.userXp?.monthlyXps : "N/A",
        weeklyXps: item?.userXp?.weeklyXps ? item?.userXp?.weeklyXps : "N/A",
        todayXps: item?.userXp?.todayXps ? item?.userXp?.todayXps : "N/A",
        email: item?.email ? item?.email : "N/A",
        id: item?.walletAddress ? item?.walletAddress : "N/A",
        currentLocationCountryName: item?.currentLocation?.countryName
          ? item?.currentLocation?.countryName
          : "N/A",
        currentLocationCountryCode: item?.currentLocation?.countryCode
          ? item?.currentLocation?.countryCode
          : "N/A",
        currentLocationCity: item?.currentLocation?.city
          ? item?.currentLocation?.city
          : "N/A",
        currentLocationLatitude: item?.currentLocation?.latitude
          ? item?.currentLocation?.latitude
          : "N/A",
        currentLocationLongitude: item?.currentLocation?.longitude
          ? item?.currentLocation?.longitude
          : "N/A",
        id: item?.deviceId ? item?.deviceId : "N/A",
        deviceId: item?.deviceId ? item?.deviceId : "N/A",
        imageUrl: item?.imageUrl ? item?.imageUrl : "N/A",
        isBlocked: item?.isBlocked ? item?.isBlocked : "N/A",
        isBlockedByAdmin: item?.isBlockedByAdmin
          ? item?.isBlockedByAdmin
          : "N/A",
        isDeleted: item?.isDeleted ? item?.isDeleted : "N/A",
        phoneCountryCode: item?.phone?.phoneCountryCode
          ? item?.phone?.phoneCountryCode
          : "N/A",
        phoneCode: item?.phone?.phoneCode ? item?.phone?.phoneCode : "N/A",
        phoneNumber: item?.phone?.phoneNumber
          ? item?.phone?.phoneNumber
          : "N/A",
        fullPhoneNumber: item?.phone?.fullPhoneNumber
          ? item?.phone?.fullPhoneNumber
          : "N/A",
        role: item?.role ? item?.role : "N/A",
        socialMediaLinksFaceBook: item?.socialMediaLinks?.faceBook
          ? item?.socialMediaLinks?.faceBook
          : "N/A",
        socialMediaLinksTwitter: item?.socialMediaLinks?.twitter
          ? item?.socialMediaLinks?.twitter
          : "N/A",
        userCompletedTasks: item?.userCompletedTasks
          ? item?.userCompletedTasks
          : "N/A",
        userTotalNfts: item?.userTotalNfts ? item?.userTotalNfts : "N/A",
      }));
      const csvContent = [
        Object.keys(formattedData[0]).join(","),
        ...formattedData.map((row) => Object.values(row).join(",")),
      ].join("\n");
      const blob = new Blob([csvContent], { type: "text/csv" });
      console.log("<==blob");
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "AllUsers.csv";
      link.click();
    } catch (error) {
      console.error("Error fetching or processing user data:", error);
      message.error("Failed to download user data");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <span className="questheading">Users</span>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <SearchBar onSearch={handleSearch} />
          <Button
            type="primary"
            style={{ margin: "5px", background: "#78633a", width: "120px" }}
            onClick={downloadUsers}
          >
            Download{" "}
          </Button>
          <Button
            type="primary"
            style={{ background: "#78633a", width: "120px" }}
            onClick={downloadAllUsers}
            loading={loading}
          >
            Download All{" "}
          </Button>
        </div>
        <Select
          value={pageLimit}
          onChange={handlePerPageChange}
          style={{ width: "80px" }}
        >
          <Option value={10}>10</Option>
          <Option value={20}>20</Option>
          <Option value={30}>30</Option>
          <Option value={40}>40</Option>
        </Select>
      </div>
      <Row gutter={[16, 16]}>
        {allUserData?.result?.docs?.map((item, index) => (
          <Col xs={24} sm={24} md={24} lg={24} xl={24} key={item?._id}>
            <div
              className="mb-3"
              style={{ cursor: "pointer" }}
              onClick={() => {
                showUserClicked(item);
              }}
            >
              <CustomCard userData={item} isDeleted={item.isDeleted} />
            </div>
          </Col>
        ))}
      </Row>
      <div className="d-flex justify-content-end mt20">
        <Pagination
          current={currentPage}
          pageSize={
            allUserData?.result?.itemsPerPage
              ? allUserData?.result?.itemsPerPage
              : 9
          }
          total={allUserData?.result?.totalDocs}
          onChange={(page) => pageChanged(page)}
          showSizeChanger={false}
        />
      </div>
      <Modal
        title={false}
        footer={false}
        open={userDetailModal}
        onCancel={handleCloseUserDetail}
        width={800}
      >
        <UserDetail
          userDetailData={userDetailData}
          toggleBlockUser={(isBlock, userId) =>
            handleBlockUser(isBlock, userId)
          }
          deleteUser={(userId) => handleDeleteUser(userId)}
          restoreUser={(userId) => handleRestoreUser(userId)}
        />
      </Modal>

      <Modal
        title="Restore User"
        visible={restoreUserModal}
        onCancel={() => setRestoreUserModal(false)}
        footer={[
          <Button key="cancel" onClick={() => setRestoreUserModal(false)}>
            Cancel
          </Button>,
          <Button key="restore" type="primary" onClick={handleConfirmRestore}>
            Restore
          </Button>,
        ]}
      >
        <p>Do you want to restore this user?</p>
      </Modal>
    </>
  );
};

export default UserCardData;
