import { Breadcrumb, Layout, Menu, theme } from "antd";
import { useEffect, useLayoutEffect, useState } from "react";
import AnnouncementListing from "../GameCenter/Announcement";
import SidebarCryptoLogo from "../../../../Assets/Logo/sidebarlogo/sibarLogo.png";
import jwt_decode from "jwt-decode";
import UserCardData from "../Quest/User";
import TaskHints from "../Quest/TaskHints";
import FamiliesCard from "../GameCenter/Families";
import QuestListing from "../Quest/QuestsListing";
import CreateTask from "../Tasks/CreateTask";
import AllTaskListing from "../Tasks/AllTask";
import CreateNewQuest from "../Quest/CreatenewQuest";
import { Link, useNavigate } from "react-router-dom";
import PrizePool from "../TaskandReward/PrizePool";
import CreateCollection from "../GameCenter/CreateCollection";
import { LogoutOutlined } from "@ant-design/icons";
import NFTCollectionListing from "../GameCenter/CollectionListing";
import AllPrizesListing from "../TaskandReward/AllPrizes";
import VotesListing from "../Votes/VotesListig";
import Gravatar from "react-gravatar";
import {
  FolderIcon,
  GameCenterIcon,
  QuestIcon,
  TaskandReward,
  TasksIcon,
  WalletIcon,
} from "../../../SharedComponents/icons/icons";
import Boost from "../Boost";
import Wallet from "../Wallet";
import Reports from "../Reports";
import ExchangeLink from "../ExchangeLink";
const { Header, Content, Footer, Sider } = Layout;

function getItem(label, key, path, icon, children) {
  return {
    path,
    key,
    icon,
    label,
    children,
  };
}

const rootSubmenuKeys = ["1", "6", "9", "12", "15", "16", "19"];
const Sidebar = () => {
  const items = [
    getItem("Quests", "1", "quest", <QuestIcon />, [
      getItem("Quest Listing", "23", "/all-quest"),
      getItem("Add Quest", "24", "/create-quest"),
    ]),

    getItem("Tasks", "9", "tasks", <TasksIcon />, [
      getItem("All Tasks", "10", "/tasks-list"),
      // getItem("Add New Task", "11", "/create-task"),
    ]),

    getItem("Game Boost", "70", "boost", <FolderIcon />, [
      getItem("Boosts ", "71", "/boosts"),
    ]),

    getItem("Wallet", "80", "wallet", <WalletIcon />, [
      getItem("Wallet Listing ", "81", "/wallet"),
      getItem("ExchangeLink ", "82", "/exchangeLink"),
    ]),

    getItem("Prizes", "12", "task-&-rewards", <TaskandReward />, [
      getItem("Prize Listing", "27", "/prize-pool-list"),
      getItem("Add Prize Pool", "13", "/prize-pool"),
    ]),

    getItem("Game Center", "16", "game-center", <GameCenterIcon />, [
      getItem("Users", "34", "/users"),
      getItem("Families page", "18", "/family-page"),
      getItem("Create Collection", "25", "/create-collection"),
      getItem("All Collections", "26", "/nft-collection-listing"),
    ]),

    getItem("Announcement", "30", "announcement", <FolderIcon />, [
      getItem("Announcement ", "17", "/announcements"),
    ]),

    getItem("Votes", "28", "votes", <FolderIcon />, [
      getItem("All Votes", "29", "/votes"),
      // getItem("lorem ipsum", "21", "/store2"),
    ]),

    getItem("Reports", "90", "reports", <TasksIcon />, [
      getItem("All Reports", "91", "/reports"),
    ]),
  ];

  const [collapsed, setCollapsed] = useState(false);
  const [logout, setLogout] = useState(false);
  const [selectedKey, setSelectedKey] = useState("");
  const [defaultPathKey, setDefaultPathKey] = useState("");
  const [defaultOpenKey, setDefaultOpenKey] = useState("");
  const [decondedAdminToken, setDecondedAdminToken] = useState("");

  const [render, setRender] = useState(false);
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const history = useNavigate();

  useLayoutEffect(() => {
    const token = window.sessionStorage?.getItem("token");
    if (!token) {
      history("/");
    }
  }, []);
  useEffect(() => {
    var admintoken = window.sessionStorage?.getItem("token");
    console.log("decode error");
    if (admintoken) {
      let decondToken = jwt_decode(admintoken);
      setDecondedAdminToken(decondToken);

      setSelectedKey(window.location.pathname || "/location");
      if (window.location.pathname == "/all-quest") {
        setDefaultPathKey("23");
        setDefaultOpenKey("1");
      } else if (window.location.pathname == "/create-quest") {
        setDefaultPathKey("24");
        setDefaultOpenKey("1");
      } else if (window.location.pathname == "/tasks-list") {
        setDefaultPathKey("10");
        setDefaultOpenKey("9");
      } else if (window.location.pathname == "/prize-pool") {
        setDefaultPathKey("13");
        setDefaultOpenKey("12");
      } else if (window.location.pathname == "/announcements") {
        setDefaultPathKey("17");
        setDefaultOpenKey("30");
      } else if (window.location.pathname == "/create-collection") {
        setDefaultPathKey("25");
        setDefaultOpenKey("16");
      } else if (window.location.pathname == "/nft-collection-listing") {
        setDefaultPathKey("26");
        setDefaultOpenKey("16");
      } else if (window.location.pathname == "/prize-pool-list") {
        setDefaultPathKey("27");
        setDefaultOpenKey("12");
      } else if (window.location.pathname == "/votes") {
        setDefaultPathKey("29");
        setDefaultOpenKey("28");
      } else if (window.location.pathname == "/family-page") {
        setDefaultPathKey("18");
        setDefaultOpenKey("16");
      } else if (window.location.pathname == "/users") {
        setDefaultPathKey("34");
        setDefaultOpenKey("16");
      } else if (window.location.pathname == "/boosts") {
        setDefaultPathKey("71");
        setDefaultOpenKey("70");
      } else if (window.location.pathname == "/wallet") {
        setDefaultPathKey("81");
        setDefaultOpenKey("80");
      } else if (window.location.pathname == "/exchangeLink") {
        setDefaultPathKey("94");
        setDefaultOpenKey("34");
      } else if (window.location.pathname == "/reports") {
        setDefaultPathKey("91");
        setDefaultOpenKey("90");
      }
    } else {
      history("/");
    }
  }, [render, defaultPathKey]);
  const handleMenuChange = (e) => {
    console.log("handleMenuChange");
    console.log("e.k =========", e.key);
    setSelectedKey(window.location.pathname || "/location");
    setRender(!render);
    // setSelectedKey(!selectedKey)
  };

  const [openKeys, setOpenKeys] = useState(["1"]);

  const onOpenChange = (keys) => {
    console.log("openchange", keys);
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    console.log("latest openkey", latestOpenKey);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      console.log("inside if");
      setOpenKeys(keys);
    } else {
      console.log("inside else");
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  useEffect(() => {
    const urlKeyPair = {
      "/tasks-list": "9",
      "/prize-pool": "12",
      "/announcements": "30",
      "/boosts": "70",
      "/create-collection": "16",
      "/nft-collection-listing": "16",
      "/prize-pool-list": "12",
      "/votes": "28",
      "/all-quest": "1",
      "/create-quest": "1",
      "/family-page": "16",
      "/users": "16",
      "/wallet": "80",
      "/reports": "90",
      "/exchangeLink": "11",
    };

    if (urlKeyPair.hasOwnProperty(window.location.pathname)) {
      setOpenKeys([urlKeyPair[window.location.pathname]]);
    } else {
      alert("not found");
    }
  }, []);
  const handleLogOut = () => {
    setLogout(true);
    window.sessionStorage.clear();
    history("/");
  };
  if (logout) {
    return null;
  }
  return (
    <Layout
      style={{
        minHeight: "100vh",
      }}
    >
      <Sider
        width={250}
        collapsible={false}
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
        style={{
          overflow: "auto",
          height: "100vh",
          position: "fixed",
          left: 0,
          top: 0,
          bottom: 0,
          backgroundColor: "white",
        }}
      >
        <div className="sidebar-logo-wrapper">
          <img src={SidebarCryptoLogo} className="w97" />
        </div>

        <Menu
          className="mt90"
          onClick={(e) => handleMenuChange(e)}
          // defaultSelectedKeys={[`${JSON.stringify(defaultPathKey)}`]}
          mode="inline"
          //  items={items}
          items={
            // level 1 = root level

            items.map((l1_item, index) => {
              return {
                ...l1_item,
                label: <Link to={`/${l1_item?.path}`}>{l1_item?.label}</Link>,
                // level 2
                children: l1_item?.children?.map((val) => {
                  return {
                    ...val,
                    label: <Link to={val?.path}>{val?.label}</Link>,
                  };
                }),
              };
            })
          }
          // openKeys={[defaultOpenKey]}
          openKeys={openKeys}
          onOpenChange={onOpenChange}
          // defaultOpenKeys={[`${JSON.stringify(defaultPathKey)}`]}

          selectedKeys={[defaultPathKey]}
        />

        <div className="sidebar-bottom-profile d-flex justify-content-center align-items-center">
          <div>
            <Gravatar
              email={decondedAdminToken?.email}
              className="border-radius"
            />
          </div>
          <div className="ml10">
            <div className="side-bar-role">Admin</div>
            <div className="side-bar-email">{decondedAdminToken?.email}</div>
          </div>
        </div>
      </Sider>

      <Layout
        className="site-layout"
        style={{
          marginLeft: 250,
        }}
      >
        <Header
          style={{
            padding: 0,
            background: colorBgContainer,
            height: "62px",
            position: "sticky",
            top: 0,
            zIndex: 1,
            width: "100%",
            display: "flex",
            alignItems: "center",
            borderBottom: "1px solid #CECECE",
          }}
        >
          <div className="logout-btn-wrapper">
            <button
              onClick={handleLogOut}
              type="submit"
              className="logout-btn darkcolor bold"
            >
              <LogoutOutlined />
              <span className="pl10">Logout</span>
            </button>
          </div>
        </Header>

        <Content
          style={{
            margin: "10px 16px",
          }}
        >
          <Breadcrumb
            style={{
              margin: "16px 0",
            }}
          >
            {window.location.pathname == "/all-quest" && (
              <>
                <Breadcrumb.Item>Quest</Breadcrumb.Item>
                <Breadcrumb.Item>Quest Listing</Breadcrumb.Item>
              </>
            )}

            {window.location.pathname == "/create-quest" && (
              <>
                <Breadcrumb.Item>Quest</Breadcrumb.Item>
                <Breadcrumb.Item>Create new quest</Breadcrumb.Item>
              </>
            )}
          </Breadcrumb>

          <div
            style={{
              // padding: 24,
              minHeight: 360,
              // background: colorBgContainer,
              backgroundColor: "#F5F5F5",
            }}
          >
            {selectedKey === "/location" && <h1>Location</h1>}
            {selectedKey === "/boosts" && <Boost />}

            {selectedKey === "/task/hints" && <TaskHints />}
            {selectedKey === "/all-quest" && <QuestListing />}
            {/* {selectedKey === "/edit-quest" && <EditQuest />} */}
            {selectedKey === "/tasks-list" && <AllTaskListing />}
            {/* {selectedKey === "/create-task" && <CreateTask />} */}
            {selectedKey === "/users" && <UserCardData />}
            {selectedKey === "/announcements" && <AnnouncementListing />}
            {selectedKey === "/family-page" && <FamiliesCard />}
            {selectedKey === "/create-quest" && <CreateNewQuest />}
            {selectedKey === "/prize-pool" && <PrizePool />}
            {selectedKey === "/create-collection" && <CreateCollection />}
            {selectedKey === "/nft-collection-listing" && (
              <NFTCollectionListing />
            )}
            {selectedKey === "/prize-pool-list" && <AllPrizesListing />}
            {selectedKey === "/votes" && <VotesListing />}
            {selectedKey === "/wallet" && <Wallet />}
            {selectedKey === "/exchangeLink" && <ExchangeLink />}
            {selectedKey === "/reports" && <Reports />}
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};
export default Sidebar;
