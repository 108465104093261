import React, { memo, useState } from "react";
import { EyeFilled, EyeInvisibleFilled } from "@ant-design/icons";
const CustomTextInput = memo(
  ({
    value,
    label,
    placeHolderText,
    type,
    onChange,
    maxLength,
    AutoComplete,
    error,
    showEyeIcon,
    changeWidth,
    required,
    innerRef,
    onKeyDown,
    id,
    disable,
    autoFocus,
  }) => {
    const [show, setShow] = useState(false);
    function handleShow() {
      setShow(!show);
    }
    return (
      <div>
        <div>
          <label className="d-flex">
            {label} {required && "*"}
          </label>

          <input
            // type={type}
            type={
              show === false && type === "password"
                ? "password"
                : type === "number"
                ? "number"
                : "text"
            }
            // type={show === false  && type== "password" ?'password': 'text'}
            className={`${
              changeWidth === true
                ? "form-control input-feild-border w450"
                : "form-control input-feild-border w-100"
            }`}
            autocomplete={AutoComplete ? "one-time-code" : ""}
            placeholder={placeHolderText ? placeHolderText : "Fill Field"}
            value={value}
            onChange={onChange}
            maxLength={maxLength}
            required={required}
            disabled={disable}
            onKeyDown={onKeyDown}
            ref={innerRef}
            id={id}
            autoFocus={autoFocus ? true : false}
          />
          {showEyeIcon === true && value?.length > -1 ? (
            <span>
              {" "}
              {show === true ? (
                <span>
                  <EyeFilled onClick={handleShow} className="eyeicon" />
                </span>
              ) : (
                <span>
                  <EyeInvisibleFilled
                    onClick={handleShow}
                    className="eyeicon"
                  />
                </span>
              )}
            </span>
          ) : (
            ""
          )}
        </div>

        <div>
          <p className="errorText">{error}</p>
        </div>
      </div>
    );
  }
);

export default CustomTextInput;
