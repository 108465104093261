import { Pagination, Switch, Table } from "antd";
import { useEffect, useState } from "react";
import { Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { types } from "../../../store/action_types";
import {
  DeleteIcon,
  EditIcon,
  PlusIcon,
} from "../../../SharedComponents/icons/icons";
import AddBoast from "../../../SharedComponents/AddBoast";
import Description from "../TaskandReward/PrizePool/description/description";
import SearchBar from "../../../SharedComponents/SearchBar";

let addkey = Math.random();
const Boost = () => {
  const dispatch = useDispatch();
  const { allBoostCollectionData, allBoostCollectionDataloading } = useSelector(
    (state) => ({
      allBoostCollectionData: state.BoostReducer.allBoostCollectionData,
      allBoostCollectionDataloading:
        state.BoostReducer.allBoostCollectionDataloading,
    })
  );

  const { searchTextBoosts } = useSelector(
    (state) => ({
      searchTextBoosts: state.QuestReducer.searchText
    })
  );


  const [data, setData] = useState(allBoostCollectionData);
  const [openBoastForm, setOpenBoastForm] = useState(false);
  const [searchText, setSearchText] = useState("");
  
  const textDisplay = (text) => {
    if (text) {
      if (text.length > 31) {
        text = text.slice(0, 31) + "...";
      }
    }
    return text;
  };

  useEffect(() => {
    setData(allBoostCollectionData);
  }, [allBoostCollectionData]);

  const showAddBoostModal = () => {
    setOpenBoastForm(true);
  };
  const handleStatusChange = (clickedItem) => {
    dispatch({
      type: types.BoostUpdateRequest,
      payload: {
        id: clickedItem._id,
        updateBoostData: {
          isListed: clickedItem.isListed === false ? true : false,
        },
        currentPage: allBoostCollectionData?.result?.gameBoosts?.currentPage,
      },
    });
  };
  const columns = [
    {
      title: "Boasts",
      dataIndex: "",
      key: "",

      render: (item) => (
        <div key={item._id}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <img src={item.imageLink} className="taskavatarimage" />
            <p style={{ margin: "0px" }}>{item.name}</p>
          </div>
        </div>
      ),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",

      render: (item) => (
        <div key={item}>
          <div className="location">{textDisplay(item)}</div>
        </div>
      ),
    },
    {
      title: "Price ",
      dataIndex: "price",
      key: "price",

      render: (item) => (
        <>
          <div key={item._id}>{item}</div>
        </>
      ),
    },

    {
      // title: "Type of Clue",
      title: "NFT ID",
      dataIndex: "_id",
      key: "_id",

      render: (item) => (
        // <div  >{item}</div>
        <div className="typeofclue" key={item._id}>
          {" "}
          {item}
        </div>
      ),
    },

    {
      title: "Status",
      dataIndex: "",
      key: "",

      render: (item) => (
        <div>
          <Switch
            key={item._id}
            defaultChecked={item.isListed}
            disabled={allBoostCollectionDataloading}
            onClick={(e) => {
              handleStatusChange(item);
            }}
          />
        </div>
      ),
    },
  ];

  const handleCloseAddBoostModal = () => {
    addkey = Math.random();
    dispatch({
      type: types.UploadBoastImageReset,
    });
    setOpenBoastForm(false);
  };

  const onChangeTaskPagination = (page) => {
    dispatch({
      type: types.BoostListingRequest,
      payload: {
        searchText: searchText ? searchText :searchTextBoosts,
        page,
      },
    });
  };

  const handleSearch = (searchText) => {
    setSearchText(searchText);
    dispatch({
      type: types.BoostListingRequest,
      payload: {
        searchText: searchText,
        page: 1,
      },
    });
    // const filteredData = allBoostCollectionData.filter((item) =>
    //   item.name.toLowerCase().includes(searchText.toLowerCase())
    // );
  
    // setData(filteredData);
  };

  useEffect(() => {
    dispatch({
      type: types.BoostListingRequest,
      // payload: 1,
      payload: { page: 1, name: "ayaz" }, 
    });
  }, []);
  

  return (
    <div>
      <div className="d-flex  align-items-baseline mb-3">
      <SearchBar onSearch={handleSearch}/>

        <div>
          <div className="addbtn" onClick={showAddBoostModal}>
            {" "}
            Add Boost <PlusIcon />{" "}
          </div>
        </div>
      </div>
      <Table
        pagination={false}
        columns={columns}
        dataSource={data?.result?.gameBoosts?.docs}
      />

      <div className="d-flex justify-content-end mt20">
        <Pagination
          current={allBoostCollectionData?.result?.gameBoosts?.currentPage}
          pageSize={10}
          total={allBoostCollectionData?.result?.gameBoosts?.totalDocs}
          onChange={(page) => onChangeTaskPagination(page)}
          showSizeChanger={false}
        />
      </div>
      <Modal
        title={false}
        footer={false}
        open={openBoastForm}
        onCancel={handleCloseAddBoostModal}
        width={1000}
      >
        {openBoastForm ? (
          <AddBoast key={addkey} handleCloseModal={handleCloseAddBoostModal} />
        ) : null}
      </Modal>
    </div>
  );
};

export default Boost;
