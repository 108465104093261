import React, { memo } from "react";
import { Card, Avatar, Tooltip } from "antd";
import UserLogo from "../../../Assets/Users/user.png";
import { UserOutlined } from "@ant-design/icons";
import { LocationIcon } from "../icons/icons";

const CustomCard = memo(({ userData }) => {
  const formattedXP = userData?.userXp?.totalXps
    ? userData?.userXp?.totalXps.toFixed(2)
    : 0;

  const createdDate = new Date(userData.createdAt);
  const currentDate = new Date();
  const daysSinceCreation = Math.floor(
    (currentDate - createdDate) / (1000 * 60 * 60 * 24)
  );

  const daysAgo =
    daysSinceCreation > 0 ? `${daysSinceCreation} days ago` : "Today";

  return (
    <Card
      style={{
        width: "100%",
        borderRadius: "20px",
        padding: "5px",
        background: userData.isDeleted
          ? "radial-gradient(circle, rgba(208,70,20,1) 49%, rgba(233,148,185,1) 100%)"
          : "",
      }}
      headStyle={{ padding: 0 }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "10px",
        }}
      >
        <div>
          <Avatar
            size={44}
            src={userData.imageUrl || UserLogo}
            icon={!userData.imageUrl && <UserOutlined />}
            style={{ marginRight: 16 }}
          />
        </div>
        <div>
          <strong style={{ marginRight: "10px", width: "200px" }}>
            {userData.nickName}
          </strong>
          <span
            style={{
              fontSize: "9px",
              fontWeight: "500",
              borderRadius: "10px",
              padding: "5px 7px 5px 7px",
              background: userData.isBlocked ? "#F87171" : "",
              color: userData.isBlocked ? "white" : "",
              marginRight: "10px",
            }}
          >
            {userData.isBlocked ? "Blocked" : ""}
          </span>
          <div>
            <LocationIcon />{" "}
            <span
              style={{
                font: "Montserrat",
                fontWeight: "400",
                lineHeight: "14.63px",
                color: "#78633A",
              }}
            >
              {userData.locationName}, {userData.countryCode}, {userData.city}
            </span>
          </div>
        </div>

        <div style={{ flex: 1, textAlign: "right" }}>
          <Tooltip title={`Joined: ${userData.createdAt}`}>
            <span style={{ fontWeight: "500" }}>Joined: {daysAgo}</span>
          </Tooltip>
          <br />
          {/* <LocationIcon />{' '}
          <span style={{ font: "Montserrat", fontWeight: '400', lineHeight: "14.63px", color: "#78633A" }}>
            {userData.countryCode}, {userData.city}
          </span> */}
        </div>
      </div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          <Tooltip title={userData.walletAddress && userData.walletAddress}>
            <div>
              <strong>Wallet:</strong>{" "}
              {`${
                userData.walletAddress
                  ? `${userData.walletAddress.substring(
                      0,
                      6
                    )}...${userData.walletAddress.slice(-4)}`
                  : ""
              }`}
            </div>
          </Tooltip>
        </div>
        <div>
          <Tooltip title={userData.countryCode}>
            <div>
              <strong>Country Code:</strong>{" "}
              {`${
                userData.countryCode ? userData.countryCode.substring(0, 6) : ""
              }`}
            </div>
          </Tooltip>
        </div>
        <div>
          <Tooltip title={userData.city}>
            <div>
              <strong>City:</strong>{" "}
              {`${userData.city ? userData.city.substring(0, 6) : ""}`}
            </div>
          </Tooltip>
        </div>
        <div>
          <Tooltip title={userData.level && userData.level}>
            <div>
              <strong>Level:</strong> {`${userData.level || ""}`}
            </div>
          </Tooltip>
        </div>

        <div>
          <Tooltip title={formattedXP && formattedXP}>
            <div>
              <strong>User Xp:</strong> {`${formattedXP || ""}`}
            </div>
          </Tooltip>
        </div>
      </div>
    </Card>
  );
});

export default CustomCard;
