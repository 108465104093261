import axios from "axios";
import { BASEURL } from "../Constants";

const token = window.sessionStorage.getItem("token");

export const axiosConfig = axios.create({
  baseURL: BASEURL,
  headers: {
    "Cache-Control": "no-cache",
    Accept: "application/json",
    Authorization: `bearer ${token}`,

    "Content-Type": "application/json",
  },
});

export const axiosFormConfig = axios.create({
  baseURL: BASEURL,
  headers: {
    Accept: "application/json",
    "Content-Type": "multipart/form-data",
    Authorization: `bearer ${token}`,
  },
});
export const FORMPOST = axiosFormConfig.post;
export const POST = axiosConfig.post;
export const PUT = axiosConfig.put;
export const GET = axiosConfig.get;
export const DELETE = axiosConfig.delete;
