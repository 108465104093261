import axios from "axios";
import { Map, Marker, GoogleApiWrapper } from "google-maps-react-18-support";
import React from "react";
class GoogleMapUpdateComponent extends React.Component {
  constructor(props) {
    super(props);
    if (props?.latUpdate && props?.longUpdate) {
      this.getCityAndCountry(props?.latUpdate, props?.longUpdate);
    }

    this.state = {
      markers: [
        {
          title: "The marker`s title will appear as a tooltip.",
          name: "SOMA",
          position: { lat: props?.latUpdate, lng: props?.longUpdate },
          // position: { lat: 31.469692, lng: 74.272842 },
        },
      ],
    };

    this.onClick = this.onClick.bind(this);
  }

  onClick(t, map, coord) {
    const { latLng } = coord;
    const lat = latLng.lat();
    const lng = latLng.lng();
    this.getCityAndCountry(lat, lng);

    this.setState((previousState) => {
      return {
        markers: [
          // ...previousState.markers,
          {
            title: "",
            name: "",
            position: { lat, lng },
          },
        ],
      };
    });
  }

  getCityAndCountry = async (lat, long) => {
    console.log("getCityAndCountry called");
    const {
      data: { results },
    } = await axios.get(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${long}&key=AIzaSyAQPJobaSibKj0ukXQag1aRYn9Kpck9MKw`
    );

    const { address_components } = results[0];

    let countryFilterResult = address_components.filter(
      (x) => x.types.filter((t) => t === "country").length > 0
    )[0];

    let cityFilterResult = address_components.filter(
      (x) =>
        x.types.filter(
          (t) =>
            t === "locality" ||
            t === "administrative_area_level_2" ||
            t === "administrative_area_level_1"
        ).length > 0
    )[0];

    let data = {
      country: countryFilterResult ? countryFilterResult.long_name : "",
      countryCode: countryFilterResult ? countryFilterResult.short_name : "",
      city: cityFilterResult ? cityFilterResult.long_name : "",
      longitude: long,
      latitude: lat,
    };
    this.props.getCityCountryVal(data);
  };

  render() {
    return (
      <div>
        <Map
          google={this.props.google}
          style={{ width: "100%", height: "200px" }}
          // className={"map"}
          containerStyle={{
            width: `${this.props.updateTask ? "47%" : "41%"}`,
            height: "29%",
            position: "absolute",
          }}
          zoom={14}
          initialCenter={{
            lat: this.props?.latUpdate,
            lng: this.props?.longUpdate,
          }}
          onClick={this.onClick}
        >
          {this.state.markers.map((marker, index) => (
            <Marker
              key={index}
              title={marker.title}
              name={marker.name}
              position={marker.position}
            />
          ))}
        </Map>
      </div>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyAQPJobaSibKj0ukXQag1aRYn9Kpck9MKw",
})(GoogleMapUpdateComponent);
