import React, { memo, useEffect, useState } from "react";
import { Card, Checkbox, Select, Modal, Upload, Pagination } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { types } from "../../../../../store/action_types";
import CustomToster from "../../../../../SharedComponents/CustomToaster";
import {
  AddStepButton,
  DeleteIcon,
} from "../../../../../SharedComponents/icons/icons";
import { timeToOpenAfterPreviousArrayData } from "../../../../../Utils/constants";
import { Formik, ErrorMessage } from "formik";

let newArr = [];
let newTempArrVal = [];
let selectedIndex = 0;

let count = 1;

// const validationSchema = Yup.object().shape({
//   task: Yup.string().required("Task is required"),
//   taskTime: Yup.number().required("Time is required"),
//   prizePool: Yup.string().required("Prize pool is required"),
//   // Add validation for "Time to open after previous" and "Prize Pool"
//   timeToOpenAfterPrevious: Yup.number().required(
//     "Time to open after previous is required"
//   ),
//   prizePool: Yup.string().required("Prize pool is required"),
// });

const EditQuestCard = memo(
  ({
    resetTasks,
    open,
    FetchUpdatedTaskArray,
    handleQuestTaskData,
    editRecordData,
    prizePoolListingAllData,
    setDisableRegion,
    setFieldValue,
    formikValues,
  }) => {
    const { editQuestModalOpen, allTaskWithCountryandCityData } = useSelector(
      (state) => ({
        editQuestModalOpen: state.QuestReducer?.editQuestModalOpen,
        allTaskWithCountryandCityData:
          state.TaskReducer.allTaskWithCountryandCityData,
      })
    );

    const dispatch = useDispatch();
    const [showTaskModal, setShowTaskModal] = useState(false);
    const [selectedTaskvalue, setSelectedTaskValue] = useState("");
    const [selectedTaskArray, setSelectedTaskArray] = useState([1]);
    const [currentPage, setCurrentPage] = useState(1);
    const [data, setData] = useState([]);
    const [selectDisabled, setSelectDisabled] = useState(false);

    useEffect(() => {
      count = 1;
      dispatch({
        type: types.GetAllTaskRequest,
        payload: 1,
      });

      console.log("empty -> formikValues?.editQuestCards: ");

      // const previousEditor = formikValues?.editQuestCards
      //   ? [
      //       ...formikValues?.editQuestCards,
      //       {
      //         setSelectedTaskValue: [],
      //         taskTime: "",
      //         prizePool: "",
      //       },
      //     ]
      //   : [
      //       ...formikValues?.editQuestCards,
      //       {
      //         setSelectedTaskValue: [],
      //         taskTime: "",
      //         prizePool: "",
      //       },
      //     ];

      setFieldValue("editQuestCards", selectedTaskArray);
      if (editRecordData) {
        setData(editRecordData?.taskArray);
        editRecordData?.taskArray.map((item, index) => {
          let newTempArr2 = [...data];
          newTempArr2.push(item);
          newTempArrVal.push(index);
        });
        setSelectedTaskArray(newTempArrVal);
        setFieldValue("editQuestCards", newTempArrVal);
      } else {
        setSelectedTaskArray([1]);
      }
    }, []);

    useEffect(() => {}, [selectedTaskArray]);

    useEffect(() => {
      console.log("data useEffect", data);
    }, [data]);

    useEffect(() => {
      count = 1;
    }, [editQuestModalOpen]);

    useEffect(() => {
      if (selectedTaskArray.length > 0) {
        setDisableRegion(true);
      }
      if (selectedTaskArray.length === 0) {
        newArr = [];
        setDisableRegion(false);
      }
    }, [selectedTaskArray]);

    const handleTaskModal = (index) => {
      selectedIndex = index;
      if (data[index]) {
        setSelectedTaskValue(data[index].task);
      } else {
        setSelectedTaskValue("");
      }
      setShowTaskModal(true);
    };

    const handleTaskCloseModal = () => {
      setShowTaskModal(false);
    };

    const handleDelete = (index) => {
      const updatedArray = selectedTaskArray.filter(
        (item, i) => item !== index
      );
      setSelectedTaskArray(updatedArray);
      let data1 = null;
      let newTempArr = [...data];
      newTempArr[index] = data1;
      setData(newTempArr);
      handleQuestTaskData(newTempArr);
    };

    useEffect(() => {
      if (count !== 1 && prizePoolListingAllData) {
        let newTempArr = [...data];
        const newArray = newTempArr.map((obj) => ({ ...obj, prizePool: null }));
        setData(newArray);
        handleQuestTaskData(newArray);
      }
      if (prizePoolListingAllData) {
        count++;
      }
    }, [prizePoolListingAllData]);

    useEffect(() => {
      if (resetTasks) {
        console.log("gonna reset here");
        let newTempArr = [...data];
        const newArray = newTempArr.map((obj) => ({ ...obj, prizePool: null }));
        setData(newArray);
        handleQuestTaskData(newArray);
      }
    }, [resetTasks]);

    const hanldePrizePoolList = (val, fieldName, index) => {
      console.log(
        "formik.values -> hanldePrizePoolList: ",
        formikValues?.editQuestCards
      );
      console.log(
        "hanldePrizePoolList onChange event...",
        val,
        index,
        fieldName
      );
      console.log("hanldePrizePoolList data", data);
      // setFieldValue(`data[${index}].${fieldName}`, val);
      setFieldValue(fieldName, val);
      const foundObject = prizePoolListingAllData?.find(
        (item) => item.title === val
      );
      let data1 = {
        task: data[index]?.task,

        taskTime: data[index]?.taskTime,
        prizePool: foundObject,
      };
      let newTempArr = [...data];
      newTempArr[index] = data1;
      setData(newTempArr);
      handleQuestTaskData(newTempArr);
    };

    const hanldeTimeToOpenAfterPrevious = (e, fieldName, index) => {
      console.log(
        "hanldeTimeToOpenAfterPrevious onChange event...",
        e,
        index,
        fieldName
      );

      console.log("task ...............: ", formikValues?.taskTime);
      setFieldValue(
        fieldName,
        formikValues?.taskTime?.length
          ? [...formikValues?.taskTime, e]
          : [...formikValues?.taskTime, e]
      );
      console.log("after: task ...............: ", formikValues?.taskTime);

      // setFieldValue(`data[${index}].${fieldName}`, e);
      // setFieldValue(fieldName, e);
      let data1 = {
        task: data[index]?.task,
        taskTime: e * 60000,

        prizePool: data[index]?.prizePool,
      };

      let newTempArr = [...data];
      newTempArr[index] = data1;
      setData(newTempArr);
      handleQuestTaskData(newTempArr);
    };

    const onCheckboxChange = (e, selected) => {
      console.log("seclected clicked", selected);
      console.log("dataaa clicked", data);
      console.log("e clicked", e);
      const found = data.some((obj) => obj?.task?._id === e._id);

      if (found) {
        setSelectDisabled(true);
        CustomToster({
          type: "error",
          message: "This task is already selected.",
        });
      } else {
        setSelectDisabled(false);
      }
      setSelectedTaskValue(e);
    };
    const handleSubmitSelectedValue = () => {
      let data1 = {
        task: selectedTaskvalue,
        taskTime: data[selectedIndex]?.taskTime,
        prizePool: data[selectedIndex]?.prizePool,
      };

      let newTempArr = [...data];
      newTempArr[selectedIndex] = data1;
      setData(newTempArr);
      handleQuestTaskData(newTempArr);
      setShowTaskModal(false);
      setSelectedTaskValue("");
    };

    const handleAddNewTaskRow = (index) => {
      let temparr = [...selectedTaskArray];
      if (selectedTaskArray.length > 0) {
        temparr.push(selectedTaskArray[temparr.length - 1] + 1);
      } else {
        temparr.push(0);
      }

      setSelectedTaskArray(temparr);
      let data1 = null;
      let newTempArr = [...data];
      if (selectedTaskArray.length > 0) {
        newTempArr[selectedTaskArray[temparr.length - 1] + 1] = data1;
      } else {
        newTempArr[0] = data1;
      }

      setData(newTempArr);
      handleQuestTaskData(newTempArr);
    };

    const onChangeQuestTaskPagination = (page) => {
      setCurrentPage(page);
      FetchUpdatedTaskArray(page);
    };

    return (
      <div>
        {selectedTaskArray?.map((index, num) => {
          return (
            <div className="mt10" key={index}>
              <Card key={index}>
                <div className="questlistwrapper">
                  <div>{num + 1}</div>
                  <div>
                    <label>Search of task</label>
                    <div className="searchtaskforquestwrapper">
                      <div className="row col-11">
                        <div className="col-md-3 pl0">
                          <div
                            className="cursor"
                            onClick={() => handleTaskModal(index)}
                          >
                            <PlusIcon />
                          </div>
                        </div>
                        <div className="col-md-9 pl0">
                          {data?.map((item, index1) => {
                            return (
                              <>
                                <div>
                                  {(index === index1 && item?.label) ||
                                    (index === index1 && item?.task?.name) ||
                                    (index === index1 && item?.name)}
                                </div>
                              </>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <label>Time to open after previous </label>
                    <Select
                      value={
                        data[index]?.taskTime / 60000
                          ? data[index]?.taskTime / 60000
                          : "Time to open after previous"
                      }
                      style={{ width: "100%" }}
                      placeholder="Time to open after previous"
                      onChange={(e) =>
                        hanldeTimeToOpenAfterPrevious(e, "taskTime", index)
                      }
                      // onChange={(e) =>
                      //   updateFieldValues(index, "taskTime", e * 60000)
                      // }
                      options={timeToOpenAfterPreviousArrayData}
                    >
                      {timeToOpenAfterPreviousArrayData?.map((option) => (
                        <Select.Option
                          key={option?.label}
                          value={option?.value}
                        >
                          {option?.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </div>

                  <div>
                    <label>Prize Pool</label>
                    <Select
                      value={data[index]?.prizePool?.title}
                      style={{ width: "100%" }}
                      placeholder="Select any one"
                      onChange={(value) =>
                        hanldePrizePoolList(value, "prizePool", index)
                      }
                      // onChange={(value) =>
                      //   updateFieldValues(index, "prizePool.title", value)
                      // }
                    >
                      {prizePoolListingAllData?.map((option) => (
                        <Select.Option key={option._id} value={option.title}>
                          {option.title}
                        </Select.Option>
                      ))}
                    </Select>
                    <ErrorMessage
                      name={`data[${index}].prizePool`}
                      component="div"
                      className="error-message"
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      marginTop: "19px",
                      marginLeft: "15px",
                    }}
                    onClick={() => {
                      handleDelete(index);
                    }}
                  >
                    <DeleteIcon />
                  </div>
                </div>
              </Card>
            </div>
          );
        })}
        <div
          className="d-flex justify-content-center mt10"
          onClick={() => handleAddNewTaskRow(selectedTaskArray.length - 1)}
        >
          <AddStepButton />
        </div>

        <Modal
          title={false}
          footer={false}
          open={showTaskModal}
          onCancel={handleTaskCloseModal}
          width={1200}
        >
          <div className="row col-12">
            {allTaskWithCountryandCityData?.result?.tasks?.docs
              .filter((item) => item.status !== "de-activate")
              .map((item, index) => {
                return (
                  <div className="col-md-6 mt10">
                    <Card>
                      <div className="row col-12">
                        <div className="col-md-3">
                          <Checkbox
                            key={item?.name}
                            onChange={() =>
                              onCheckboxChange(item, selectedTaskvalue?.name)
                            }
                            checked={item?.name === selectedTaskvalue?.name}
                            value={item?.name}
                          />
                        </div>
                        <div className="col-md-3">
                          {item?.label || item?.name}
                        </div>
                        <div className="col-md-3">
                          {item?.country || item?.location?.countryCode}
                        </div>
                        <div className="col-md-3">
                          {item?.city || item?.location?.city}
                        </div>
                      </div>
                    </Card>
                  </div>
                );
              })}
          </div>
          <div className="d-flex justify-content-between mt40">
            <div className="d-flex mt40">
              <div>
                <button
                  disabled={selectDisabled}
                  type="submit"
                  className="savebtn border p1030"
                  onClick={() => handleSubmitSelectedValue()}
                  // onChange={(value) =>
                  //   updateFieldValues(index, "prizePool.title", value)
                  // }
                >
                  Select
                </button>
              </div>
              <div className="ml30">
                <button
                  type="submit"
                  className="cancelbtn border p1030 "
                  onClick={() => handleTaskCloseModal()}
                >
                  Cancel
                </button>
              </div>
            </div>
            <div className="d-flex justify-content-end ">
              <Pagination
                current={currentPage}
                pageSize={10}
                total={allTaskWithCountryandCityData?.result?.tasks?.totalDocs}
                onChange={(page) => onChangeQuestTaskPagination(page)}
                showSizeChanger={false}
              />
            </div>
          </div>
        </Modal>
      </div>
    );
  }
);

export default EditQuestCard;

const PlusIcon = () => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 30 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.5" y="0.5" width="29" height="27" rx="3.5" fill="white" />
    <path
      d="M24 5H6V23H24V5ZM20 15H16V19H14V15H10V13H14V9H16V13H20V15Z"
      fill="#2D2E2E"
    />
    <rect x="0.5" y="0.5" width="29" height="27" rx="3.5" stroke="#F5F5F6" />
  </svg>
);
